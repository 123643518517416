import React from 'react'
import '../home/Home.css'
import background from '../../assets/CakeHut_Hero.jpg'
import CakeCard from '../../components/cakecards/CakeCard';
import { ButtonGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


function Home() {
  const navigate = useNavigate()

    return (
        <>
            <div className='container-fluid bg_cakes'>
                <div className='row pt-3'>
                    <div className='col-sm-12 homeimage mt-3'>
                        <h1 className='maintext'>QUALITY CAKES with UNBEATABLE PRICES<br />
in South Australia</h1>
<h5 className='premises mt-4'>We bake on premises!</h5>
<div className='btnmain mt-5'>
<button className='btntelp'
type="button" ><a href='tel:08 8123 2678' className='tel'>Tel: 08 8123 2678 </a>
</button>
<button className='btnmenu'  onClick={() => navigate("/menu")}>Menu
</button>
</div>
                        {/* <img src={background} className='img img-fluid' alt='background' /> */}
                    </div>
                </div>
      <CakeCard />

            </div>
        </>
    )
}

export default Home