import React from 'react'
import './About.css'

function About() {
    return (
        <div className='main-about'>
            <div className='container-about'>
            <div className='first-sec'>
                <h2 className='about-text'>About Us</h2>
            </div>
            <div className='second-sec'>
                <h2 className='first-text'>We Bake on Premises!</h2>
                <p className='second-text ms-4'>We are based at Shop 2A 985 South Road, Melrose Park in Adelaide, 5039 who provide quality cakes at unbeatable prices!</p>
                <h3 className='third-text ms-4'>We specialise in making:</h3>
                <li className='third-sec-li ms-4'>Birthday Cakes</li>
                <li className='third-sec-li ms-4'>Custom Cakes</li>
                <li className='third-sec-li-last ms-4'>Special Ocassion Cakes and everything else in between.</li>
                <p className='second-tex ms-4'>The cakes are all made on premises and if you ask our customers, they are so tasty and will come back for more!  We make our cakes<br></br>
                as if we were making it for ourselves; that's how much pride we have in our cakes.
                <p className='weoffer'> We offer free writing on cakes!</p>
                </p>
                <h3 className='five-text ms-4'>The type of cakes we make also includes:</h3>
                <li className='five-text-li ms-4'>Edible Images</li>
                <li className='five-text-li-2 ms-4'>Custom Cakes - 10"/12"/14"/16" Square Slabs available on pre-order</li>
                <li className='five-text-li-3 ms-4'>Tortes & Gateaux</li>
                <li className='five-text-li-4 ms-4'>Baked Cheese Cakes</li>
                <li className='five-text-li-5 ms-4'>Continental Cheese Cakes</li>
                <li className='five-text-li-6 ms-4'>Specialty Cakes</li>
                <h4 className='six-text'>Tel: 08 8123 2678 </h4>
                
            </div>
            </div>
        </div>
    )
}

export default About