import React, { useState, getUserData } from 'react';
import  { useRef  } from 'react';
import './Contact.css'
import { Carousel } from "react-bootstrap"
import pic from '../../assets/1.jpg'
import emailjs from 'emailjs-com';


const Contact = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
const [status, setStatus] = useState("")
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('SERSDFS', 'DSFWFSD', 
    
      {
        name: name,
        email: email,
        message: message
      }
    , 'SDFSDFWSRFDS')
      .then(response => {
        console.log('SUCCESS!', response);
        setName("")
        setEmail("")
        setMessage("")


        

        
        setStatus('SUCCESS');
      }, error => {
        console.log('FAILED...', error);
      });
  }
  // const [values, setValues] = useState({
  //   name: '',
  //   email: '',
  //   message: ''
  // })
    
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   emailjs.send('service_1o5buia', 'template_r3ly5ta', values, 'user_Yuqa0U8GSg0rBG8ka2thS')
  //     .then(response => {
  //       console.log('SUCCESS!', response);
  //       setValues({
  //         name: '',
  //         email: '',
  //         message: ''
  //       });
  //       setStatus('SUCCESS');
  //     }, error => {
  //       console.log('FAILED...', error);
  //     });
  // }

  
  // function sendEmail(e)  {
  //   e.preventDefault();
  //   emailjs.sendForm(
  //     'service_1o5buia',
  //     'template_r3ly5ta',
  //    form.current,
  //     'PUGY233yMc-FU6Emz'

  //   ).then((result) => {
  //     console.log(result.text);
  //   }, (error) => {
  //     console.log(error.text);

  //   });
  //   }


  // let name, value;
  // const getUserData = (event) => {
  //   name = event.target.name;
  //   value = event.target.value;

  //   setUser({ ...user, [name]: value });

  // };
  // const postData = async (e) => {
  //   e.preventDefault();
  //   const { name, phone, email, message } = user;
  //   // if alse used for the avoid the empty data
  //   if (name && phone && email && message) {
  //     const res = await fetch(
  //       'https://newform-7fc63-default-rtdb.firebaseio.com//basit.json',
  //       {
  //         method: 'POST',
  //         headers: {

  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           name,
  //           phone,
  //           email,
  //           message,
  //         })
  //       });
  //     // this 34-41 line used for the empty the form after the submtion.
  //     if (res) {
  //       setUser({
  //         name: "",
  //         phone: "",
  //         email: "",
  //         message: "",
  //       });
  //       alert("Data Store Successfuly");
  //     }
  //   }
  //   else {
  //     alert("Plz fill all the data")
  //   }

  // };
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className='first-sec-contact'>
        <h2 className='contact-text'>CONTACT US</h2>
        <p className='contact-text-2'>For more information, fill in your contact details and we’ll get back to you. </p>
      </div>
      <div class="container">
        <div className='-form' method="POST">
          <div class="row">
            <div class="col mt-5">
              <h2 className='get-h'>GET IN TOUCH</h2>
              <p className='get-pra mt-5'>If you have a question, please fill in the enquiry form or<br></br> simply call us on 08 8123 2678.<br></br>

                Have an event coming up, why not send us your details<br></br> to coordinate your next event.</p>
              
              <form ref={form} onSubmit={handleSubmit}>
              <div className='input-data' method="POST">
                <label>Name</label>
                <input value={name} onChange={(e)=>setName(e.target.value)} className='input_1' name='name' 
          ></input><br></br><br></br>
                <label class>Phone</label>
                <input className='input_1' name='phone' 
            ></input><br></br><br></br>
                <label>Email</label>
                  <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" className='input_1' name='user_email' 
            ></input><br></br><br></br>
                <label>Message</label>
                  <input value={message} onChange={(e) => setMessage(e.target.value)} className='input_last' name='message' 
                  ></input><br></br>
                <button className='but_1' >Submit</button>
              </div>
              </form>
            </div>
            <div class="col mt-5">
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={pic}
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={pic}
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={pic}
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
              <div className='contact-info'>
                <p className='phon_mail'>Tel: 08 8123 2678<br></br>
                  Email: info@thecakehut.com.au</p>
                <p className='address'>Shop 2A - 985 South Road<br></br>
                  Melrose Park,  Adelaide, SA 5039</p>
                <p className='time_det'><b>Trading Hours</b><br></br>
                  Open 7 Days 10am - 8pm</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe className='map mt-5' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d836681.8945449928!2d138.0506577431528!3d-34.99947880338535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab735c7c526b33f%3A0x4033654628ec640!2sAdelaide%20SA%2C%20Australia!5e0!3m2!1sen!2sus!4v1651047027930!5m2!1sen!2sus"
        style={{ width: "90%", height: "450px", border: "none" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Contact